import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AuthService {
  login (user: any) {
    return axios
      .post(API_URL + 'auth/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data.access_token))
          localStorage.setItem('userData', JSON.stringify(response.data.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
    localStorage.removeItem('userData')
    return axios
      .post(API_URL + 'auth/logout', {
      })
  }

  emailVerify (data: any) {
    return axios
      .post(API_URL + 'auth/email/verify', {
        token: data
      }, { headers: authHeader() })
  }

  passwordRecoveryEmail (data: any) {
    return axios
      .post(API_URL + 'auth/password/recovery-email', {
        email: data.email
      }, { headers: authHeader() })
  }

  newPassword (data: any) {
    return axios
      .post(API_URL + 'auth/password/new', {
        password: data.password,
        password_confirmation: data.passwordRecovery,
        token: data.token
      }, { headers: authHeader() })
  }

  register (user: any) {
    return axios.post(API_URL + 'auth/register', {
      name: user.name,
      email: user.email,
      terms: true,
      password: user.password,
      password_confirmation: user.password_confirmation,
      ref_code: user.ref_code
    }, { headers: authHeader() })
  }

  changePassword (data) {
    return axios.post(API_URL + 'users/changePassword', {
      current_password: data.current_password,
      new_password: data.new_password,
      password_confirmation: data.password_confirmation
    },
    { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new AuthService()
